<template>
  <div class="pl-2 pr-4">
    <gallery-banner
      v-if="selectedStyleGuideBanner && selectedStyleGuideBanner[0] && selectedStyleGuideBanner[0].renditions && selectedStyleGuideBanner[0].renditions.original"
      :src="selectedStyleGuideBanner[0].renditions.original.mezzaninePreview"
      :banner="selectedStyleGuideBanner[0]"
    />
    <div v-if="!selectedNode.children || !selectedNode.children.length" class="text-center w-full py-52 sm:py-28 md:py-36 lg:py-52">
      No folders available.<br/>
    </div>
    <div v-else class="flex flex-wrap justify-items-stretch">
      <img
        v-for="(node, nIndex) in __children"
        :title="node.buttonName"
        :key="'galleryNode' + (node.styleGuideId || nIndex)"
        :src="node.renditions.original.fpo || node.renditions.original.mezzaninePreview || node.renditions.thumbnail"
        class="w-1/6 h-auto px-4 pb-8 cursor-pointer box-border"
        @click="handleSelectedNode({ nodeData: node })"
      >
    </div>

    <div v-for="(subCatNodeList, subCatName, subCatIdx) in __subCats">
      <div class="flex bg-primary-red text-white px-2 xl:px-3 py-1.5 xl:py-3 rounded-sm leading-4 items-center my-7 text-lg font-bold">
        {{subCatName}}
      </div>

      <div class="flex flex-wrap justify-items-stretch">
        <img
          v-for="(node, nIndex) in subCatNodeList"
          :title="node.buttonName"
          :key="'galleryNode' + (node.styleGuideId || nIndex)"
          :src="node.renditions.original.fpo || node.renditions.original.mezzaninePreview || node.renditions.thumbnail"
          class="w-1/6 h-auto px-4 pb-8 cursor-pointer box-border"
          @click="handleSelectedNode({ nodeData: node })"
        >
      </div>
    </div>

  </div>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import useStyleGuideDirectory from '@/hooks/styleGuideDirectory.js';

export default {
    name: 'GalleryFolders',

    components: {
        GalleryBanner: defineAsyncComponent(() => import('./GalleryBanner.vue'))
    },

    setup () {
        const store = useStore();
        const selectedStyleGuide = computed(() => store.getters['styleGuideTree/getSelectedStyleGuide']);
        const selectedStyleGuideBanner = computed(() => store.getters['styleGuideTree/getStyleGuideBanner']);

        const { selectedNode, handleSelectedNode } = useStyleGuideDirectory();

        const exceptionButtonPaths = {
          "Consumer Products Tool Kits/Marvel's Spider-Man": 'Fact Sheets',
          "Consumer Products Tool Kits/Marvel's Avengers": 'Fact Sheets',
          "Consumer Products Tool Kits/Fact Sheet PDFS": 'Fact Sheets',
        }

        const __children = computed(() =>
          selectedNode.value.children.filter(n => !Object.keys(exceptionButtonPaths).includes(`${(n.path||'')}/${n.buttonName}`) )
        )

        const __subCats = computed(() => {
          let __excepList = selectedNode.value.children.filter(n => Object.keys(exceptionButtonPaths).includes(`${(n.path||'')}/${n.buttonName}`) )
          let __ret = {}
          __excepList.forEach(n => {
            const subCat = exceptionButtonPaths[`${(n.path||'')}/${n.buttonName}`]
            if (!__ret[subCat]) __ret[subCat] = []
            __ret[subCat].push(n)
          })
          return __ret
        })

        return {
            selectedNode,
            __children,
            __subCats,
            handleSelectedNode,
            selectedStyleGuide,
            selectedStyleGuideBanner
        };
    }
};
</script>
